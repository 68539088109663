(function () {
  const icons = Array.from(document.fonts)
    .filter(font => font.family.startsWith("Material Symbols"))
    .map(x => x.load());

  // hide icons until font loaded
  if (icons.length) {
    const style = document.createElement("style");
    style.textContent = `i { visibility: hidden !important; }`;
    document.head.appendChild(style);
    Promise.all(icons).then(() => style.remove());
  }
})();

(function () {
  const localhost = {
    6001: "Development",
    6002: "Development",
    7001: "Local",
    7002: "Local",
  };

  const hosts = {
    "127.0.0.1": localhost,
    "localhost": localhost,
    "exp.app.teachcraft.ai": "Experimental",
    "dev.app.teachcraft.ai": "Development",
  };

  const config = hosts[window.location.hostname.toLowerCase()];
  const environment = (config && config[window.location.port]) || config || "Production";
  Blazor.start({ environment });
})();
